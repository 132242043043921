import { createRoot, Suspense } from '@wordpress/element';
import Media from './Media';
 
window.addEventListener( 'DOMContentLoaded', () => {
    const element = document.querySelector(
        '.wp-block-goland-features-goland-media-gallery'
    );
    if ( element ) {
        const attributes = { ...element.dataset };
        const root = createRoot(element);
        root.render(
            <Suspense fallback={ <div className="wp-block-placeholder" /> }>
                <Media { ...attributes } />
            </Suspense>
        );
    }
} );