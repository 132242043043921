import { useState, useEffect } from "@wordpress/element";
import FsLightbox from "fslightbox-react";
// import Swiper core and required modules

import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper";
import _ from "lodash";
export default function Media({ showCaptions,selectedVersion }) {

	const property = goland.property || {};
	let images =  property.images;
	
	function getWindowSize() {
		const { innerWidth, innerHeight } = window;
		return { innerWidth, innerHeight };
	}

	// Save all image url's to a single array
	const [toggler, setToggler] = useState(false);
	const [windowSize, setWindowSize] = useState(getWindowSize());

	// Track the window size to switch the view to a carousel on mobile
	useEffect(() => {
		function handleWindowResize() {
			setWindowSize(getWindowSize());
		}

		window.addEventListener("resize", handleWindowResize);

		return () => {
			window.removeEventListener("resize", handleWindowResize);
		};
	}, []);
	

	let urlArray = [];
	let captionArray = [];
	let galleryImages = [];

	images.forEach((item) => {
			if(item.type == "outside" || item.type == "inside") {
				galleryImages.push(item);
				let galleryitem = _.find(item.versions, ['name', "full"])
				urlArray.push(galleryitem.url);
				captionArray.push(item.caption);
			}
	});

	if (galleryImages.length == 0) {
		return "";
	}
	

  // main image is the first image
	const mainimage_data = galleryImages[0];
	let main_image_version= _.find(galleryImages[0].versions, ['name', "large"]);
	const mainImage = <img src={main_image_version.url} alt={mainimage_data.alt} onClick={() => setToggler(!toggler)} />;

	let lastimage_data = galleryImages[4];
	if(!lastimage_data) {
		lastimage_data = _.last(galleryImages);
	}
	let lastimage_version = _.find(galleryImages[galleryImages.length - 1].versions, ['name', "thumb"]);
	const lastImage = <img src={lastimage_version.url} alt={lastimage_data.alt} onClick={() => setToggler(!toggler)} />;



	const displayArray = galleryImages.slice(1, 4);

	const imageElements = displayArray.map((item, index) => {
		let imageversion = _.find(item.versions, ['name', 'medium']);
		return <img  alt={item.alt} src={imageversion.url} key={index} onClick={() => setToggler(!toggler)} />;
	});

	const lightbox = <FsLightbox toggler={toggler} sources={urlArray} thumbs={urlArray} showThumbsOnMount={showCaptions ? false : true} captions={showCaptions ? captionArray : []} type="image" />


	if (windowSize.innerWidth > 768) {
		return (
			<>
				<div className="images-container">
					<div className="main-image">{mainImage}</div>
					<div className="images">
						{imageElements}
						{urlArray.length > 5 && 
							<div className="last-image">
								{	lastImage }
								{urlArray.length > 5 && <p style={{pointerEvents: 'none'}}>+{urlArray.length - 5}</p>}
							</div>
						}
					</div>
				</div>
				{lightbox}
			</>
		);
	}
	return (
		<>
			<Swiper
				spaceBetween={20}
				slidesPerView={1}
				modules={[Navigation, Pagination]}
				navigation
				loop={true}
				pagination={{
					type: "fraction",
				}}
			>
				{urlArray.map((url, index) => {
					return (
						<SwiperSlide key={index}>
							<img src={url} onClick={() => setToggler(!toggler)} />
						</SwiperSlide>
					);
				})}
			</Swiper>
			{lightbox}
		</>
	);
}
